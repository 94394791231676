import { createStore } from 'vuex'

export default createStore({
  state: {
    baseUrlImg : 'https://bow.olsys.in/storage/',
    accessToken: null,
    userData: null,
    placeholderImg: '/assets/img/placeholder.png',
    loginSuccessMessage: '',
  },
  getters: {
  },
  mutations: {

  },
  actions: {
  },
  modules: {
  }
})
