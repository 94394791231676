import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Page/Home.vue'

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Profile/Profile.vue')
  },
  {
    path: '/business-information',
    name: 'BusinessInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Profile/BusinessInfo.vue')
  },
  {
    path: '/company-information',
    name: 'CompanyInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Profile/CompanyInfo.vue')
  },

  {
    path: '/oprational-information',
    name: 'OprationalInformation',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Profile/OprationalInformation.vue')
  },


  {
    path: '/active-container-on-wheel',
    name: 'AtiveContainer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/AtiveContainer.vue')
  },
  {
    path: '/add-new-container-on-wheel',
    name: 'NewContainerOnWheel',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/wheel.vue')
  },
  {
    path: '/edit-container-on-wheel/:id',
    name: 'EditContainerOnWheel',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/EditContainerOnWheel.vue')
  },
  {
    path: '/edit-container-on-wheel-childe/:id',
    name: 'EditContainerOnWheelChiled',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/EditContainerOnWheelChiled.vue')
  },
  {
    path: '/view-more-list/:id',
    name: 'ViewMoreList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/ViewMoreList.vue')
  },
  {
    path: '/container-list',
    name: 'ContainerList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/ContainerList.vue')
  },

  {
    path: '/container-search',
    name: 'ContainerSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/SearchContainer.vue')
  },

  {
    path: '/containers',
    name: 'InfoSearchContainePrice',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/InfoSearchContainePrice.vue')
  },

  {
    path: '/make-booking-container/:id',
    name: 'BookingContainer',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/BookingContainer.vue')
  },
  {
    path: '/confirme-booking',
    name: 'ConfirmBooking',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Container/ConfirmBooking.vue')
  },


  {
    path: '/pending-booking-list',
    name: 'PendingBookingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Bookings/PendingBookingList.vue')
  },

  {
    path: '/rejected-booking-list',
    name: 'RejecteBookingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Bookings/RejecteBookingList.vue')
  },

  {
    path: '/confirmed-booking-list',
    name: 'ConfirmedBookingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Bookings/ConfirmedBookingList.vue')
  },
  {
    path: '/confirmed-booking-list-by-consumer',
    name: 'ConfirmedBookingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Bookings/ConfirmedBookingListByConsumer.vue')
  },
  // For Exporter
  {
    path: '/booking-list-exporter',
    name: 'BookingListExporter',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Exporter/BookingList.vue')
  },

  {
    path: '/booking-status/:id',
    name: 'BookingStatus',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/BookingStatus.vue')
  },
  {
    path: '/container-photo/:id',
    name: 'ContainerPhotos',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Exporter/ContainerPhotos.vue')
  },
  {
    path: '/payment-page',
    name: 'PaymentBooking',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Exporter/PaymentBooking.vue')
  },
  // Shipping  Line Area 
  {
    path: '/shipping-line-booking-list',
    name: 'ShippingLineBookingList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/ShipingLine/ShippingLineBookingList.vue')
  },
  {
    path: '/link-request',
    name: 'LinkRequest',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/ShipingLine/LinkRequest.vue')
  },
  // planning area transporter

  {
    path: '/planing-list',
    name: 'PlaningList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Planing/PlaningList.vue')
  },
  {
    path: '/add-new-planing',
    name: 'NewPlaning',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Transporter/Planing/NewPlaning.vue')
  },


// Auth Section
  {
    path: '/privacy-and-policy',
    name: 'PrivecyAndPolicy',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportantDocc/PrivecyAndPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'TurmsAndCondition',
    component: () => import(/* webpackChunkName: "about" */ '../views/ImportantDocc/TurmsAndCondition.vue')
  },
  {
    path: '/set-new-password/:random',
    name: 'SetNewPassword',
    component: () => import(/* webpackChunkName: "SetNewPassword" */ '../views/Auth/SetNewPassword.vue')
  }
,  
{
  path: '/reset-password/:random',
  name: 'ResetPassword',
  component: () => import(/* webpackChunkName: "ResetPassword" */ '../views/Auth/ResetPassword.vue')
}
,  
    {
    path: '/registeration',
    name: 'Registeration',
    component: () => import(/* webpackChunkName: "about" */ '../views/Page/Registeration.vue')
  },
  {
    path: '/register-your-account',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Register.vue')
  },
  {
    path: '/',
    name: 'HomeLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/Login.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/forget-password',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/ForgetPassword.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/otp-verification',
    name: 'OtpVirification',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/OtpVirification.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/mobile-otp-verification',
    name: 'MobileOtpLogin',
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth/MobileOtpLogin.vue'),
    meta: { headerVisible: 'hide' }
  },
  {
    path: '/error-404', name: 'Error404',
    component: () => import('@/views/Error/Error404.vue'),
    meta: {
      headerVisible: 'hide',
    },
  },{
    // path: '*',
    path: "/:catchAll(.*)", // Unrecognized path automatically matches 404
    redirect: '/error-404',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
