<template>
	<ul class="menu-inner py-1 disabled-card">
		<!-- Dashboards -->
		<li class="menu-item" :class="{ 'active': $route.path === '/home' }">
			<router-link  :to="(user.status == 'Approved') ? '/home' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			   <!-- <i class="menu-icon tf-icons bx bx-home-circle"></i> -->
			   <i class="menu-icon fi fi-rr-home"></i>
			   <div data-i18n="Dashboards">Dashboards</div>
			</router-link>
		</li>
		<!-- Containers On Wheel -->
		<li class="menu-item" :class="{ 'active': $route.path === '/add-new-container-on-wheel' }">
			<router-link  :to="(user.status == 'Approved') ? '/add-new-container-on-wheel' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
				<i class="menu-icon  fi fi-rs-truck-container"></i>
			   <div data-i18n="Port">Add New Container</div>
			</router-link>
		</li>

		<!--Active Containers On Wheel -->
		<li class="menu-item" :class="{ 'active': $route.path === '/container-list' }" >
			<router-link  :to="(user.status == 'Approved') ? '/container-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
				<i class="menu-icon  fi fi-rr-container-storage"></i>
			
			   <div data-i18n="Port"> Container List</div>
			</router-link>
		</li>



		<li class="menu-item text-start" :class="{ 'active': $route.path === '/pending-booking-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/pending-booking-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Pending Booking Request List
			   </div>
		  </router-link>
		</li>

		<li class="menu-item text-start" :class="{ 'active': $route.path === '/confirmed-booking-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/confirmed-booking-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Confirmed Booking List By Me
				 <!-- By Me -->
			   </div>
		  </router-link>
		</li>

		<li class="menu-item text-start" :class="{ 'active': $route.path === '/confirmed-booking-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/confirmed-booking-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Confirmed Booking By Consumer
				 <!-- By Me -->
			   </div>
		  </router-link>
		</li>

		<li class="menu-item text-start" :class="{ 'active': $route.path === '/rejected-booking-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/rejected-booking-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
				Rejected Booking List
			   </div>
		  </router-link>
		</li>

		<li class="menu-item" :class="{ 'active': $route.path === '/planing-list' }">
		  <router-link :to="(user.status == 'Approved') ? '/planing-list' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-rs-code-pull-request"></i>
			   <div data-i18n="Port">
			Planings
			   </div>
		  </router-link>
		</li>
	</ul>
</template>

<script>
    import axios from "axios";
export default {
	name: 'TranporterNav',
     data() {
		
       return {
         user: {},
       
       };
     },

     methods: {
		profileCompleateORNot() {
                this.isLoading = true;
                axios
                    .get(`customer/user/${JSON.parse(localStorage.getItem("userData")).id}`, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("accessToken"),
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                      
						this.user = response.data.data


                    })
                    .catch((error) => {
                   
                        if (error.response.status == 401) {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("userData");
                            localStorage.removeItem("role");
                            this.$router.push({ name: "Login" });
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

     
     },
     mounted() {

		if (this.checkConnection()) {
		this.profileCompleateORNot()
            }

   
     },
  
   };
</script>


<style scoped>
.disabled-card{
        pointer-events: none !important;
      
    }
</style>