<template>
	<ul class="menu-inner py-1 disabled-card">
		<li class="menu-item" :class="{ 'active': $route.path === '/home' }">
		  <router-link :to="(user.status == 'Approved') ? '/home' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
		  <i class="menu-icon tf-icons bx bx-home-circle"></i>
		   <div data-i18n="Dashboards">Dashboard</div>
		  </router-link>
		</li>
		<li class="menu-item" :class="{ 'active': $route.path === '/container-search' }">
		  <router-link :to="(user.status == 'Approved') ? '/container-search' : '#'" class="menu-link" :class="(user.status == 'Approved') ? '' : 'text-light'">
			<i class="menu-icon fi fi-br-search"></i>
			   <div data-i18n="Port">
				Search Container
			   </div>
		  </router-link>
		</li>
	</ul>
</template>


<script>
    import axios from "axios";
export default {
	name: 'FreightForworderNav',
     data() {
       return {
         user: {},
       
       };
     },

     methods: {
   
		profileCompleateORNot() {
                this.isLoading = true;
                axios
                    .get(`customer/user/${JSON.parse(localStorage.getItem("userData")).id}`, {
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("accessToken"),
                            Accept: "application/json",
                        },
                    })
                    .then((response) => {
                      
						this.user = response.data.data


                    })
                    .catch((error) => {
                        console.error("Error loading user:", error);
                        if (error.response.status == 401) {
                            localStorage.removeItem("accessToken");
                            localStorage.removeItem("userData");
                            localStorage.removeItem("role");
                            this.$router.push({ name: "Login" });
                        }
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            },

     },
     mounted() {
    
		if (this.checkConnection()) {
		this.profileCompleateORNot()
            }

   
     },
  
   };
</script>


<style scoped> 

.disabled-card{
        pointer-events: none !important;
      
    }
</style>